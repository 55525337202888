import React, {useEffect, useState} from 'react'
import {Container} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import * as _ from "underscore";
import FilterHandler from '../shared/utils/FilterHandler';
import {changeView, toggleLoader} from '../shared/actions/setting';
import axios from 'axios';
import {getUserId, isSuper, isAdminUser} from "../shared/utils/SharedAuthentication";
import * as dateFns from "date-fns";
import {DateRangePicker} from 'rsuite';
import {dateFormat} from "../shared/utils/utils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const NotificationPage = () => {
  const dispatch = useDispatch();
  const [notificationsUpdate, setNotificationsUpdate] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [loadNotifications, setLoadNotifications] = useState([]);
  const [notificationListAll, setNotificationListAll] = useState([]);
  const [farmName, setFarmName] = useState([])
  const [type, setType] = useState([])
  const [accountList, setAccountList] = useState([]);
  const [corporateList, setCorporateList] = useState([]);
  const [severityList, setSeverityList] = useState([]);
  const [selectedCorporate, setSelectedCorporate] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedSeverity, setSelectedSeverity] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [history, setHistory] = useState([]);
  const { t, i18n } = useTranslation();

  const {handleFilter, filteredList, filters} = FilterHandler(
    setFilteredList,
    notificationListAll
  );

  const corporateListFromStore = useSelector((state) => {
    return state.modules.corporateList;
  });

  function dateFormat(date) {
    return dateFns.format(new Date(date), "yyyy-MM-dd");
  }

  function setFilteredList() {
    setLoadNotifications(filteredList);
  }
  // const selectedCorporate = useSelector((state) => {
  //   return state.modules.selectedCorporate;
  // });

  const selectedNotification = useSelector((state) => {
    return state.setting.selectedNotification;
  });

  // useEffect(() => {
  //   dispatch(toggleLoader(true));
  //   dispatch(changeView(VIEW.NOTIFICATION_ALL))
  //   axios
  //     .get(
  //       process.env.REACT_APP_HOST + `/user/` + getUserId() + `/corporate/` + selectedCorporate + `/notifications?pageSize=100&pageIndex=0`
  //     )
  //     .then((res) => {
  //       setNotifications(res.data.content.content);

  //     })
  //     .finally(() => {
  //       dispatch(toggleLoader(false));
  //     });
  // }, [selectedCorporate]);  

  useEffect(() => {
    if (!selectedNotification) return;

    setNotifications(selectedNotification);
  }, [selectedNotification]);

  useEffect(() => {
    setLoadNotifications(
      _.union(changeKey(notifications), notificationsUpdate)
    );
    setNotificationListAll(
      _.union(changeKey(notifications), notificationsUpdate)
    );
  }, [notificationsUpdate, notifications]);

  useEffect(() => {
    if (notificationListAll.length === 0) {
      return
    }
    setFarmName(_.uniq(_.pluck(notificationListAll, "farmName")))
    setType(_.uniq(_.pluck(notificationListAll, "type")))
  }, [notificationListAll])

  function changeKey(arr) {
    return arr.map((s) => {
      if (s.hasOwnProperty("startTime")) {
        s.createdAt = s.startTime;
        s.seenStatus = false;
        delete s.startTime;
      }
      return s;
    });
  }

  useEffect(() => {
    const params = {};
    if (selectedAccount) params.accountId = selectedAccount;
    if (selectedCorporate) params.corporateId = selectedCorporate;
    if (selectedSeverity) params.severity = selectedSeverity;
    if (dateRange?.startDate) params.startDate = dateRange.startDate;
    if (dateRange?.endDate) params.endDate = dateRange.endDate;

  
    dispatch(toggleLoader(true));
  
    axios
      .get(`${process.env.REACT_APP_HOST}/user/${getUserId()}/notification/size/100/index/0`, {
        params,
      })
      .then((res) => {
        const notifications = res.data.content?.map((item) => ({
          ...item,
          createdAt: item.startTime,
        })) || [];
        setNotifications(notifications); 
        setHistory(notifications); 
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Error fetching notifications.");
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [selectedAccount, selectedCorporate, selectedSeverity, dateRange]);
  
  const onCheck = (e) => {
    if (e.length === 0) {
      setDateRange(null); 
      return;
    }
  
    const startDate = dateFormat(e[0]);
    const endDate = dateFormat(e[1]);
  
    setDateRange({ startDate, endDate }); 
  };
  
  
  const onAccountChange = (e) => {
    let accountId = e.target.value;
    setSelectedAccount(accountId);
    setSelectedCorporate(null);
    if (accountId) {
      setCorporateList(corporateListFromStore.filter(corporate => corporate.accountId === accountId))
    } else {
      setCorporateList(corporateListFromStore)
    }
  };
  const onCorporateChange = (e) => {
    setSelectedCorporate(e.target.value);
  };

  const onSeverityChange = (e) => {
    setSelectedSeverity(e.target.value);
  };

  useEffect(() => {
    if (isAdminUser()) {
      dispatch(toggleLoader(true));
      axios.get(process.env.REACT_APP_HOST + '/user/' + getUserId() + '/account')
        .then(res => {
          console.log(res.data);
          setAccountList(res.data.content);
        }).catch(error => {
          if (error.response && error.response.status === 422) {
            toast.error(error.response.data.message);
          }
        }
      ).finally(() => {
        dispatch(toggleLoader(false))
      })
    }
   
  }, []);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + '/user/' + getUserId() + '/notification-severity')
      .then(res => {
        console.log(res.data);
        setSeverityList(res.data);
      }).catch(error => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      }
    ).finally(() => {
      dispatch(toggleLoader(false))
    })
  }, []);



  useEffect(() => {
    if (!selectedAccount) {
      setCorporateList(corporateListFromStore);
    }
  }, [corporateListFromStore]);
   
  

  return (
    <Container>
      <div className='full-list-view position-sticky'>
        <div className={"single-card m-rl-m-8 p-a-16"}>
          <div className={"sa-table-flex"} style={{marginBottom: 8}}>
        <span>
          <select
            className="sa-filter m-l-0"
            onChange={handleFilter}
            name={"type"}
          >
            <option value={""}>
              {filters && filters.type ? "ALL" : "Type"}
            </option>
            {type.map((item, index) => (
              <option key={index} value={item}>
                {item ? item.replaceAll('_', " ") : "NA"}
              </option>
            ))}
          </select>
          <select
            className="sa-filter sa-table-m-r-0 sa-mobile-view-m"
            onChange={handleFilter}
            name={"farmName"}
          >
            <option value={""}>
              {filters && filters.farmName ? "ALL" : "Farm Name"}
            </option>
            {farmName.map((item, index) => (
              <option key={index} value={item}>
                {item == "" ? "NONE" : item}
              </option>
            ))}
          </select>
            {isAdminUser() && (
                <span
                  className={"sa-table-float-left participant-filter"}
                  name={""}
                  onChange={(e) => onAccountChange(e)}
                >
                  <select className="sa-filter sa-mobile-view-m">
                    <option value={""}>{"Account"}</option>
                    {accountList &&
                      accountList.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </span>
              )}
              {isAdminUser() && (
                <span
                  className={"sa-table-float-left participant-filter"}
                  name={""}
                >
                  <select 
                    className="sa-filter sa-mobile-view-m"
                    onChange={(e) => onCorporateChange(e)}
                    value={selectedCorporate || ''}
                  >
                    <option value={""}>{"Corporate"}</option>
                    {corporateList &&
                      corporateList.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </span>
                )}

<span
                  className={"sa-table-float-left participant-filter"}
                  name={""}
                  onChange={(e) => onSeverityChange(e)}
                >
                  <select className="sa-filter sa-mobile-view-m">
                    <option value={""}>{"Severity"}</option>
                    {severityList &&
                      severityList.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </span>
          
            <DateRangePicker
              style={{ zIndex: 9999 }}
              disabledDate={(date) => date > new Date()}
              disabled={false}
              onChange={(e) => onCheck(e)}
              oneTap={false}
              onOk={(e) => onCheck(e)}
              showWeekNumbers={true}
              appearance={"default"}
              placeholder={t("title.TODAY")}
              ranges={[
                {
                  label: "Today",
                  value: [new Date(), new Date()],
                },
                {
                  label: "Yesterday",
                  value: [
                    dateFns.addDays(new Date(), -1),
                    dateFns.addDays(new Date(), -1),
                  ],
                },
                {
                  label: "Last 7 days",
                  value: [dateFns.subDays(new Date(), 6), new Date()],
                },
                {
                  label: "Last 30 days",
                  value: [dateFns.subDays(new Date(), 30), new Date()],
                },
              ]}
            />

        </span>
          </div>
          <div className="sa-table-container notification-calc-height">
            <table className="table table-borderless">
              <thead>
              <tr>
                <th className={"sa-table-head-sticky"}>Notification</th>
                <th className={"sa-table-head-sticky"}>Type</th>
                <th className={"sa-table-head-sticky"}>Farm Name</th>
                <th className={"sa-table-head-sticky"}>Plot Name</th>
                <th className={"sa-table-head-sticky"}>Date</th>
              </tr>
              </thead>
              <tbody>
              {loadNotifications.length > 0 &&
                loadNotifications.sort(function (a, b) {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                }) &&
                loadNotifications.map((item, index) => (
                  <tr key={index}>
                    <td className={'sa-table-data'}>{item.message}</td>
                    <td className={'sa-table-data'}>{item.type ? item.type.replace("_", " ") : "NA"}</td>
                    <td className={'sa-table-data'}>{item.farmName ? item.farmName : "NA"}</td>
                    <td className={'sa-table-data'}>{item.plotName ? item.plotName : "NA"}</td>
                    {item.type === 'DEVICE_OFFLINE' ? (
                      <td className={'sa-table-data'}>
                        {item.createdAt.slice(0, 10)} - <br/>
                        {item.endTime ? item.endTime.slice(0, 10) : "NA"}
                      </td>
                    ) : (
                      <td className={'sa-table-data'}>{item.createdAt.slice(0, 10)}</td>
                    )}

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default NotificationPage
