import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormHandler from "../../../shared/utils/FormHandler";
import FeatherIcon from "feather-icons-react";

const ManualIrrigationPopup = ({
  confirmationHeading,
  confirmationDescription,
  showDurationField,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    initForm,
  } = FormHandler(handleConfirmationSuccess, validateConfirmationDialog);

  // Initialize the form on component mount
  useEffect(() => {
    initForm({});
  }, []);

  function handleConfirmationSuccess() {
    onSubmit({ onSuccess: true, duration: values.duration });
  }

  function validateConfirmationDialog(values) {
    const errors = {};
    // if (!values.duration) {
    //   errors.duration = "Duration is required";
    // }
    return errors;
  }

  return (
    <div className="sa-popup-bg">
      <div className="sa-popup">
        <form className="sa-modal-box-style" onSubmit={handleSubmit} noValidate>
          <div className="sa-popup-header">
            <span className="sa-model-heading">{"CONFIRMATION"}</span>
            <div
              className="sa-popup-close-icon"
              onClick={() => onSubmit(null)} 
            >
              <FeatherIcon className="sa-modal-close-icon" icon="x" />
            </div>
          </div>
          <div className="sa-modal-content p-l-16 pr-4">
            <div className="warning-heading text-red">
              {confirmationHeading || t("default.HEADING")}
            </div>
            <div className="warning-text">
              {confirmationDescription || t("default.DESCRIPTION")}
            </div>

            {showDurationField && <div className="form-group m-b-16 m-t-20">
              <input
                type="number"
                id="duration"
                name="duration"
                className={`form-control ${errors.duration ? "warning-input" : ""}`}
                value={values.duration || ""}
                onChange={handleChange}
                onBlur={handleOnBlur}
                placeholder={t("placeHolder.ENTER_IRRIGATION_DURATION")}
              />
              {errors.duration && (
                <p className="warning-input-msg">{errors.duration}</p>
              )}
            </div> }

          </div>
          <div className="sa-popup-btn p-t-10">
            <button
              type="button"
              className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
              onClick={() => onSubmit(null)}
            >
              {t("button.CANCEL")}
            </button>
            <button
              type="submit"
              className="sa-popup-secondary-btn-style btn-sa-danger"
            >
              {t("button.CONFIRM")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ManualIrrigationPopup;
