import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import {hasPermission,} from "../../utils/Authentication";
import {toggleLoader} from "../../shared/actions/setting";
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import {useDispatch} from "react-redux";
import {DateRangePicker} from "rsuite";
import * as dateFns from "date-fns";
import {toast} from "react-toastify";
import FarmScoutingView from "./farm-scouting-view";
import DatePicker from "react-datepicker";
import {formatDisplayEnumValue, toCapsFirst, sortAndSeparateTasks} from "../../utils/Utils";
import FormHandler from "../../shared/utils/FormHandler";
import {validateScoutUpdate} from "../../utils/FormValidationRules";
import {getSelectedFarmScout} from "../../actions/farm";
import {option} from "react-timekeeper/lib/components/styles/time-dropdown";
import FilterHandler from "../../shared/utils/FilterHandler";
import {ExportToCsv} from "export-to-csv";
import * as _ from "underscore";
import {findIndex} from "underscore";

import {useTranslation} from "react-i18next";
import {Typeahead} from "react-bootstrap-typeahead";
import {getUserId} from "../../shared/utils/SharedAuthentication";
import {dateFormat} from "../../shared/utils/utils";

const FarmScouting = () => {

  const {t, i18n} = useTranslation();
  const {id} = useParams();

  let history = useHistory();

  const dispatch = useDispatch();

  const {farmId} = useParams();
  const [scouts, setScouts] = useState([]);
  const [data, setData] = useState([]);
  const [scoutId, setScoutId] = useState([]);
  const [scoutIndex, setScoutIndex] = useState([]);
  const [isModelVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [formErr, setFormErr] = useState(null);
  const [activeUsers, setactiveUsers] = useState([]);
  const [selectedScout, setSelectedScout] = useState(null);
  const [category, setCategory] = useState([]);
  const [uniqueDescriptions, setUniqueDescriptions] = useState([]);
  const [uniqueNames, setUniqueNames] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [approve, setApprove] = useState(false);
  const [scoutsListAll, setScoutsListAll] = useState([]);
  const [isVisibleView, setIsVisibleView] = useState(false);
  const [plot, setPlot] = useState([]);
  const [dateRange, setDataRange] = useState({
    fromDate: dateFormat(new Date(Date.now() - 604800000)),
    toDate: dateFormat(new Date(Date.now() + 604800000)),
  });
  const ref = useRef();


  function exportData() {
    const data = [];
    scouts.forEach(scouts => {
      data.push({
        "Note": scouts.note,
        "Deadline": scouts.endDate,
        "Category": scouts.category.replace(/_/g, " "),
        "Assignee": scouts.assignee ? scouts.assignee.lastName : '',
        "Status": scouts.status
      });
    });
    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'FarmScouting',
      useBom: true,
      noDownload: false,
      headers: ["Note", "Deadline", "Category", "Assignee", "Status"],
      filename: "FarmScouting",
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);
  }


  const {
    handleFilter,
    filteredList,
    filters
  } = FilterHandler(setFilteredList, scoutsListAll)

  function setFilteredList() {
    setScouts(filteredList)
  }


  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValue,
    handleOnBlur,
    initForm
  } = FormHandler(farmScouting, validateScoutUpdate);

  function farmScouting() {
    setIsLoading(true);
  }

  function filterActiveUsers(data) {
    const activeUser = [];
    data.map((user) => {
      if (user.status === "ACTIVE") {
        activeUser.push(user);
      }
    });
    setactiveUsers(activeUser)
  }

  function differenceDays(endDate) {
    var date1 = new Date(endDate);
    var date2 = new Date();
    var DifferenceTime = date2.getTime() - date1.getTime();
    var DifferenceDays = Math.floor(DifferenceTime / (1000 * 3600 * 24));
    return DifferenceDays
  }

 const { tasksAfterToday, tasksBeforeToday, tasksWithoutEndDate } = sortAndSeparateTasks(scouts);

 useEffect(() => {
  axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/plot')
    .then(res => {
      setPlot(res.data.content);
    })
    .catch(error => {
      toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));
    })
 }, [farmId]);

  // to get the farm user list
  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/system-user')
      .then(res => {
        let data = res.data.content;
        filterActiveUsers(data);
      }).catch(error => {
      toast.error(t("title.SOMETHING_WENT_WRONG"));
      console.error(error);
    }).finally(() => {
      dispatch(toggleLoader(false));
    });
  }, [farmId]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    // to update
    delete values.id;
    delete values.farmId;
    delete values.time;
    delete values.createdBy;
    delete values.assignee;
    delete values.categoryName;

    if (values.assigneeId === "") {
      delete values.assigneeId;
    }

    if (values.category !== "CROP_STATUS") {
      delete values.cropStage;
    }

    endDate && (values.endDate = dateFormat(endDate));
    dispatch(toggleLoader(true));
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/scout-report/' + scoutId, values)
      .then(res => {
        let newArr = [...scouts];
        newArr[scoutIndex] = res.data.content;
        let updatedData = [...scoutsListAll]
        let updatedDataIndex = findIndex(scoutsListAll, {id: scoutId})
        updatedData[updatedDataIndex] = res.data.content
        setScoutsListAll(updatedData)
        setScouts(newArr);
        dispatch(getSelectedFarmScout(newArr));
        setIsModalVisible(false);
        toast.success(t("successMsg.SCOUT_UPDATED_SUCCESSFULLY"));
      })
      .catch(error => {
        if (error.response && error.response.status === 422) {
          setFormErr(error.response.data.message);
          toast.error(error.response.data.message);
        } else {
          toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));

        }
      })
      .finally(() => {
        setIsLoading(false);
        setConfirmationDialog(false);
        dispatch(toggleLoader(false));
        setEndDate("");
      });

  }, [isLoading]);

  useEffect(() => {
    if (!dateRange) {
      return
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/scout-report' + "?from=" + dateRange.fromDate + "&to=" + dateRange.toDate)
      .then(function (response) {
        const scoutData = (response.data.content)
        setScouts(scoutData);
        setScoutsListAll(scoutData);
        dispatch(getSelectedFarmScout(scoutData));

        if (id) {
          var data = response.data.content.filter(item => item.id === id)

          setSelectedScout(data[0])

          setIsVisibleView(true)
        }

      })
      .catch(function (error) {
      }).finally(() => {
      dispatch(toggleLoader(false));
    })
  }, [dateRange, farmId]);

  useEffect(() => {

    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/reviewer-permission')
      .then(res => {
        setCategory(res.data.content)
      }).catch(error => {
      toast.error(t("errorMsg.SOMETHING_WENT_WRONG"))
      console.error(error);
    }).finally(() => {
      setReviewList(_.find(category, {category: "SCOUTING"}))
      dispatch(toggleLoader(false));
    });
  }, [farmId])


  function getData(category) {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/get-unique-descriptions?category=' + category)
      .then(res => {
        let data = res.data.content
        // data.map((e,n)=> e.id =n)
        setUniqueDescriptions(res.data.content)
      }).catch(error => {
      toast.error(t("errorMsg.SOMETHING_WENT_WRONG"))
      console.error(error);
    }).finally(() => {
      dispatch(toggleLoader(false));
    });

    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/get-unique-names?category=' + category)
      .then(res => {
        setUniqueNames(res.data.content)
      }).catch(error => {
      toast.error(t("errorMsg.SOMETHING_WENT_WRONG"))
      console.error(error);
    }).finally(() => {
      dispatch(toggleLoader(false));
      setIsModalVisible(true);

    });
  }


  function handleChangeCategory(event) {
    getData(event.target.value)
    handleChange(event)
  }

  function handleChangeName(event) {
    handleChange(event)
    if (!event.target.value || uniqueNames.length === 0) {
      setSuggestionList([]);
      return;
    }
    let data = uniqueNames.filter(value => value.toLowerCase().startsWith(event.target.value.trim().toLowerCase()));
    setSuggestionList(data);
  }

  function onSelectName(value) {
    setValue({"name": value});
    setSuggestionList([]);
  }


  useEffect(() => {
    if (!reviewList) {
      return
    }
    setApprove(_.contains(_.pluck(reviewList.reviewers, 'id'), getUserId()))

  }, [reviewList])


  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);
  }

  function onOpenFilter() {
    let doc = document.getElementsByClassName("rs-picker-daterange-panel");
    doc[0].scrollIntoView();
  }

  function onEdit(scout, index) {
    setData([scout.description ? scout.description : ''])
    // setIsModalVisible(true);
    getData(scout.category)
    Object.keys(errors).forEach(k => errors[k] = "");
    let data = {...scout}
    data.assigneeId = scout.assignee ? scout.assignee.id : "";
    data.status = scout.status ? scout.status : "";
    scout.endDate ? setEndDate(new Date(scout.endDate)) : setEndDate("");
    setScoutIndex(index);
    setFormErr("");
    setScoutId(scout.id);
    initForm(data);

  }

  const renderScoutRow = (scout, index) => {
    const isEditable = hasPermission("WRITE") || (scout.assignee && scout.assignee.id === getUserId());
    const isDone = scout.status === "DONE";
    const statusClass = !["DONE", "FIXED", "APPROVED"].includes(scout.status) &&
                          differenceDays(scout.endDate) > 0 ? "text-danger" : "";
  
    return (
      <tr key={index}>
        <td className={"sa-table-data"}>{toCapsFirst(scout.note) || " - "}</td>
        <td className={statusClass}>{scout.endDate || " - "}</td>
        <td className={"sa-table-data"}>{formatDisplayEnumValue(scout.category) || " - "}</td>
        <td className={"sa-table-data"}>{scout.assignee ? scout.assignee.lastName : " - "}</td>
        <td className={"sa-table-data"}>{scout.status.replace("_", " ") || " - "}</td>
        <td className="sa-table-item-align">
          <div className={"sa-table-action-flex"}>
            <div
              className="sa-table-actions sa-action-m"
              data-toggle="tooltip"
              title="View"
            >
              <FeatherIcon
                onClick={() => {
                  setSelectedScout(scout);
                  setIsVisibleView(true);
                  history.push(`/${farmId}/daily-update/scouting/${scout.id}`);
                }}
                icon={"eye"}
              />
            </div>
            {isEditable && (
              <div
                className="sa-table-actions sa-action-m"
                data-toggle="tooltip"
                title="Edit"
              >
                <FeatherIcon
                  onClick={() => onEdit(scout, index)}
                  className="table-action"
                  icon={"edit"}
                />
              </div>
            )}
            {isEditable && (
              <div
                className="sa-table-actions sa-action-m"
                data-toggle="tooltip"
                title={isDone ? "DONE" : `Mark as "DONE"`}
              >
                <FeatherIcon
                  onClick={
                    !isDone
                      ? () => {
                          setConfirmationDialog(true);
                          Object.assign(values, {
                            note: scout.note,
                            assigneeId: scout.assignee ? scout.assignee.id : "",
                            category: scout.category,
                            coordinate: scout.coordinate,
                            images: scout.images,
                            status: "DONE",
                          });
                          setScoutId(scout.id);
                          setScoutIndex(index);
                          setEndDate(scout.endDate ? new Date(scout.endDate) : "");
                        }
                      : null
                  }
                  className={isDone ? "text-green" : null}
                  icon={"check-circle"}
                />
              </div>
            )}
          </div>
        </td>
      </tr>
    );
  };


  return (

    <div className={"half-list-container"}>

      <div className={"scouting-container"}>

        <Container>

          {
            <div className={"single-card m-rl-m-8 p-a-16"} hidden={isVisibleView}>
              <div className={"sa-table-flex sa-table-position"}>
                <div className={"plot-report-filter"}>
                                    <span className={"sa-table-float-left"}>
                                        <DateRangePicker disabledDate={date => date > new Date()} disabled={false}
                                                         onChange={(e) => onCheck(e)}
                                                         oneTap={false} onOk={(e) => onCheck(e)}
                                                         showWeekNumbers={true} appearance={"default"}
                                                         placeholder={dateRange.fromDate + " - " + dateRange.toDate}
                                                         onOpen={() => onOpenFilter()}
                                                         ranges={[{
                                                           label: 'Today',
                                                           value: [new Date(), new Date()]
                                                         }, {
                                                           label: 'Yesterday',
                                                           value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                                                         }, {
                                                           label: 'Last 7 days',
                                                           value: [dateFns.subDays(new Date(), 6), new Date()]
                                                         }, {
                                                           label: 'Last 30 days',
                                                           value: [dateFns.subDays(new Date(), 30), new Date()]
                                                         }]}
                                        />
                                    </span>
                  <div className="d-flex sa-table-filter sa-filter-row">
                    <select name="assignee:id" className="sa-filter sa-table-h-float-l sa-table-float-left"
                            onChange={handleFilter}>
                      <option
                        value={""}>{filters && filters['assignee:id'] ? t("title.ALL") : t("title.ASSIGNEE")}</option>
                      {activeUsers.map((user, index) => (
                          <option value={user.id} key={index} placeholder={'chain'}>{user.lastName}</option>
                        )
                      )}
                    </select>
                    <select name="status" onChange={handleFilter}
                            className="sa-filter sa-table-h-float-l  sa-table-float-left sa-table-m-r-0">
                      <option value={""}>{filters && filters.status ? t("title.ALL") : t("title.STATUS")}</option>
                      <option>{t("title.REPORTED")}</option>
                      <option>{t("title.ASSIGNED")}</option>
                      <option value={"IN_PROGRESS"}>{t("title.IN_PROGRESS")}</option>
                      <option>{t("title.DONE")}</option>
                      <option>{t('title.FIXED')}</option>
                      {approve && <option>{t("title.APPROVED")}</option>}
                    </select>
                  </div>
                </div>
                <span className={'sa-table-icon-flex  sa-table-btn-position'}>
                                <button onClick={exportData} className="sa-table-btn-secondary sa-table-btn-mute">
                                    <FeatherIcon
                                      icon={"upload"} className={"sa-table-icon-size"}/> <span
                                  className={"sa-table-icon"}>{t("button.EXPORT_CSV")}</span>
                                </button>
                                </span>
              </div>
              <div className="sa-table-container cash-flow-table-calc-height">
                <table className="table table-borderless sa-table-width">
                  <thead>
                  <tr>
                    <th className={"sa-table-head-sticky"}>{t("title.NOTE")}</th>
                    <th className={"sa-table-head-sticky"}>{t("title.DEADLINE")}</th>
                    <th className={"sa-table-head-sticky"}>{t("title.CATEGORY")}</th>
                    <th className={"sa-table-head-sticky"}>{t("title.ASSIGNEE")}</th>
                    <th className={"sa-table-head-sticky"}>{t("title.STATUS")}</th>
                    {/*{hasPermission("WRITE") &&*/}
                    <th className="sa-table-item-align sa-table-head-sticky"></th>
                    {/*}*/}
                  </tr>
                  </thead>
                  <tbody>
                    {tasksAfterToday.map((scout, index) => renderScoutRow(scout, index))}

                  {tasksAfterToday.length > 0 && tasksBeforeToday.length > 0 && (
                    <tr>
                        <td colSpan="6" className="separator-row">
                          <hr />
                        </td>
                    </tr>
                  )}

                    {tasksBeforeToday.map((scout, index) => renderScoutRow(scout, index))}

                    {(tasksAfterToday.length > 0 || tasksBeforeToday.length > 0) && tasksWithoutEndDate.length > 0 && (
                      <tr>
                        <td colSpan="6" className="separator-row">
                          <hr />
                      </td>
                    </tr>
                    )}

                    {tasksWithoutEndDate.map((scout, index) => renderScoutRow(scout, index))}
                  </tbody>
                </table>
                {scouts.length === 0 && (
                  <div className={"empty-results"}>
                    <FeatherIcon icon="info"/>
                    <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_SCOUTS,PLEASE_ADD")}</div>
                  </div>)
                }
              </div>
            </div>
          }
          {isVisibleView && <div>
            <div className={"my-3"}>
              <FeatherIcon icon={"arrow-left"} onClick={() => {
                setIsVisibleView(false)
                setSelectedScout(null)
                history.push('/' + farmId + '/daily-update/scouting');
              }}
                           className={"sa-cursor"}/> {selectedScout.note}
            </div>
            <FarmScoutingView scout={selectedScout}/>
          </div>
          }
        </Container>

        <div className={"sa-popup-bg " + (!isModelVisible && 'hide')}>
          <div className="sa-popup">
            <form onSubmit={handleSubmit} noValidate className={'sa-modal-box-style'}>
              <div className="sa-popup-header">
                <span className={'sa-model-heading'}>{t("title.EDIT_SCOUT")}</span>
                <div className="sa-popup-close-icon" onClick={() => setIsModalVisible(false)}>
                  <FeatherIcon
                    className={"sa-modal-close-icon"} icon={"x"}/>
                </div>
              </div>
              <div className="sa-popup-content">
                <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label htmlFor="email">{t("title.SUMMARY")}</label>
                    <input onBlur={handleOnBlur} onChange={handleChange}
                           value={values.note || ''} type="text"
                           className={`form-control ${errors.note && "warning-input"}`}
                           name="note"
                           placeholder={t("placeHolder.ENTER_SUMMARY")}/>
                    {errors.note && (
                      <p className="warning-input-msg ">{errors.note}</p>)}
                  </div>
                </div>
                {/*<input hidden value={values.time}/>*/}
                <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label htmlFor="assignee">{t("title.ASSIGNEE")}</label>
                    <select onBlur={handleOnBlur} onChange={handleChange}
                            value={values.assigneeId || ''}
                            className={`form-control ${errors.assigneeId && "warning-input"}`}
                            name="assigneeId">
                      <option hidden value={""}>{t("title.SELECT_ASSIGNEE")}</option>
                      {activeUsers.map((user, index) => (
                        <option value={user.id} key={index}>{user.lastName}</option>)
                      )}
                    </select>
                    {errors.assigneeId && (
                      <p className="warning-input-msg">{errors.assigneeId}</p>)}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label htmlFor="status">{t("title.STATUS")}</label>
                    <select onBlur={handleOnBlur} onChange={handleChange}
                            value={values.status || ''}
                            className={`form-control ${errors.status && "warning-input"}`}
                            name="status">
                      <option hidden value={""}>{t("title.SELECT_STATUS")}</option>
                      <option>{t("title.REPORTED")}</option>
                      <option>{t("title.ASSIGNED")}</option>
                      <option value={"IN_PROGRESS"}>{t("title.IN_PROGRESS")}</option>
                      <option>{t("title.DONE")}</option>
                      <option>{t("title.FIXED")}</option>
                    </select>
                    {errors.status && (
                      <p className="warning-input-msg">{errors.status}</p>)}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label htmlFor="status">{t("title.CATEGORY")}</label>
                    <select onBlur={handleOnBlur} onChange={handleChangeCategory}
                            value={values.category || ''}
                            className={`form-control ${errors.category && "warning-input"}`}
                            name="category">
                      <option hidden value={""}>{t("title.SELECT_CAREGORY")}</option>
                      <option>{"REPAIR"}</option>
                      <option value={"PEST_ATTACK"}>{"PEST ATTACK"}</option>
                      <option value={"DISEASE_ATTACK"}>{"DISEASE ATTACK"}</option>
                      <option>{"FLOOD"}</option>
                      <option>{"DROUGHT"}</option>
                      <option value={"EXCESS_AMOUNT_OF_HARVEST"}>{"EXCESS AMOUNT OF HARVEST"}</option>
                      <option>{t("title.FIXED")}</option>
                      <option value={"CROP_STATUS"}>{"CROP STATUS"}</option>
                    </select>
                    {errors.category && (
                      <p className="warning-input-msg">{errors.category}</p>)}
                  </div>
                </div>
                {values.category === "CROP_STATUS" && <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label>{t("title.CROP_STAGE")}</label>
                    <select onBlur={handleOnBlur} onChange={handleChange}
                            value={values.cropStage || ''}
                            className={`form-control ${errors.cropStage && "warning-input"}`}
                            name="cropStage">
                      <option hidden value={""}>{t("title.SELECT_CAREGORY")}</option>
                      <option>{"SEEDING"}</option>
                      <option>{"FLOWERING"}</option>
                      <option value={"WEED_CONTROL"}>{"WEED CONTROL"}</option>
                      <option value={"FRUIT_BEARING"}>{"FRUIT BEARING"}</option>
                      <option>{"HARVESTING"}</option>
                      <option value={"POST_HARVEST_ACTIVITIES"}>{"POST-HARVEST ACTIVITIES"}</option>
                      <option>{"IRRIGATION"}</option>
                      <option value={"FERTILIZER_APPLICATION"}>{"FERTILIZER APPLICATION"}</option>
                      <option>{"OTHER"}</option>
                    </select>
                    {errors.cropStage && (
                      <p className="warning-input-msg">{errors.cropStage}</p>)}
                  </div>
                </div> }
                {values.category && <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label htmlFor="email">{toCapsFirst(values.category.replaceAll("_", " ")) + " Name"}</label>
                    <input onBlur={handleOnBlur} onChange={handleChangeName}
                           value={values.name || ''} type="text"
                           className={`form-control ${errors.name && "warning-input"}`}
                           name="name"
                           placeholder={t("placeHolder.ENTER_NAME")}/>

                    <ul hidden={suggestionList.length === 0}
                        style={{
                          height: "150px",
                          overflowY: "auto",
                          fontSize: 10,
                          zIndex: 999,
                          position: "absolute",
                          width: "95%"
                        }}
                        className="list-group">
                      {suggestionList.length > 0 && suggestionList.map(value => (
                        <li name={"suggestion"} style={{overflowWrap: "break-word"}} className="list-group-item"
                            value={value || ''}
                            onClick={() => onSelectName(value)}
                            key={value}>{value}</li>))}
                    </ul>
                    {errors.note && (
                      <p className="warning-input-msg ">{errors.name}</p>)}
                  </div>
                </div>}
                <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label>{t("title.DEADLINE")}</label>
                    <DatePicker className={"form-control"}
                                onChange={date => setEndDate(date)}
                                selected={endDate || ""}
                                minDate={new Date()}
                                placeholderText="Select a date"
                                fixedHeight
                                dateFormat={"dd/MM/yyyy"}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group m-b-16 description">
                    <label htmlFor="email">{t("title.DESCRIPTION")}</label>

                    <Typeahead
                      onChange={(selected) => {
                        setData(selected)
                        setValue({"description": selected[0]})
                        // console.log(ref.current)
                      }}
                      onInputChange={(selected) => {
                        // Handle selections...
                        values.description = selected
                        setValue({"description": selected})
                        setData([selected])
                      }}
                      ref={ref}
                      id="my-typeahead-id"
                      labelKey={option1 => option1}
                      selected={data}
                      placeholder={t("placeHolder.ENTER_DESCRIPTION")}
                      // defaultSelected={uniqueDescriptions.filter((e)=>e === values.description )}
                      // defaultSelected={data}
                      // defaultInputValue={"T"}
                      options={uniqueDescriptions}
                    />
                    {errors.description && (
                      <p className="warning-input-msg ">{errors.description}</p>)}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label htmlFor="status">{t("title.PLOT")}</label>
                    <select onBlur={handleOnBlur} onChange={handleChangeCategory}
                            value={values.plotId || ''}
                            className={`form-control`}
                            name="plotId">
                      <option hidden value={""}>{t("title.SELECT_PLOT")}</option>
                      {plot.map((plot, index) => (
                        <option value={plot.id} key={index}>{plot.name}</option>)
                      )}
                    </select>
                  </div>
                </div>


              </div>
              {formErr && (<div className={"login-warning-msg"}>{formErr}</div>)}
              <div className="sa-popup-btn">
                <button type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                        onClick={() => setIsModalVisible(false)}>{t("button.CANCEL")}
                </button>
                <button id={'scoutingUpdate'} className="sa-popup-secondary-btn-style">
                  {t("button.UPDATE")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className={"sa-popup-bg " + (!confirmationDialog && 'hide')}>
        <div className="sa-popup">
          <div className="sa-modal-box-style">
            <div className="sa-popup-header">
              <span className={'sa-model-heading'}>{t("title.CONFIRMATION")}</span>
              <div className="sa-popup-close-icon" onClick={() => setConfirmationDialog(false)}>
                <FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className="sa-modal-content" style={{marginLeft: 16}}>
              <div
                className={"warning-heading text-green"}>{t("confirmation_msg.ARE_YOU_SURE_YOU_WANT_TO_EDIT_THIS")}
              </div>
              <div
                className={"warning-text"}>{t("confirmation_msg.THE_EDIT_ACTION_WILL_SET_STATUS_TO_DONE")}
              </div>
            </div>
            <div className="sa-popup-btn" style={{paddingTop: 16}}>
              <button className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                      onClick={() => setConfirmationDialog(false)}>{t("button.NO")}
              </button>
              <button className="sa-popup-secondary-btn-style"
                      onClick={() => {
                        setIsLoading(true);
                      }}
              >{t("button.YES")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default FarmScouting;

