import React, {useEffect, useState} from "react";
import FeatherIcon from 'feather-icons-react';
import {toCapsFirst} from "../../utils/Utils";
import FarmImages from "../farm/farm-images";
import user from "../../images/BasicDetails.svg";
import ToggleLayout from "../toggle-layout";
import {useParams} from "react-router";
import AddTask from "./add-task";
import axios from "axios";
import {toast} from "react-toastify";
import {toggleLoader} from "../../shared/actions/setting";
import FormHandler from "../../shared/utils/FormHandler";
import {useDispatch} from "react-redux";
import {validateAssignTask} from "../../utils/FormValidationRules";
import * as _ from "underscore";
import FarmTaskView from "../smart-farm/farm/farm-task-view";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../shared/utils/SharedAuthentication";

const FarmScoutingView = (props) => {
  const {farmId} = useParams();
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const [visibleToggleIndex, setVisibleToggleIndex] = useState(1);
  const [isAssignModelVisible, setIsAssignModalVisible] = useState(false);
  const [isAddModelVisible, setIsAddModalVisible] = useState(false);
  const [tasksListAll, setTasksListAll] = useState([]);
  const [relatedTask, setRelatedTask] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isTaskView, setIsTaskView] = useState(false);
  const [taskData, setTaskData] = useState(null);
  const [formErr, setFormErr] = useState(null);
  const [plotName, setPlotName] = useState("");
  const {
    values,
    errors,
    initForm,
    handleChange,
    handleSubmit,
  } = FormHandler(assignTasks, validateAssignTask);

  function assignTasks() {
    setIsLoading(true)
  }

  useEffect(() => {
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/task-report')
      .then(res => {
        setTasksListAll(res.data.content);
      }).catch(error => {
      toast.error("Something went wrong");
      console.error(error);
    })
  }, [])

useEffect (()=> {
      axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/plot/' + props.scout.plotId)
        .then(res => {
          setPlotName(res.data.content.name);
        }).catch(error => {
        toast.error("Something went wrong");
        console.error(error);
      })
    },[props.scout])

  useEffect(() => {
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/scout-report/` + props.scout.id + '/tasks')
      .then(res => {
        setRelatedTask(res.data.content);
      }).catch(error => {
      toast.error("Something went wrong");
      console.error(error);
    })
  }, [isUpdate])


  useEffect(() => {
    if (!isLoading) {
      return;
    }
    if (props.scout.id) {
      values.scoutReportId = props.scout.id
    }

    let selectedTask = _.findWhere(tasksListAll, {id: values.id});
    selectedTask.scoutReportId = props.scout.id;
    selectedTask.assigneeId = selectedTask.assignee.id;
    delete selectedTask.id;
    delete selectedTask.farmId;
    delete selectedTask.time;
    delete selectedTask.createdBy
    delete selectedTask.assignee

    // values.id === "None" && delete values.id;
    dispatch(toggleLoader(true));
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/task-report/' + values.id, selectedTask)
      .then(res => {
        // tasks[taskIndex] = res.data.content;
        // setTasks(tasks);
        setIsAssignModalVisible(false);
        setIsUpdate(!isUpdate);
        toast.success("Task updated successfully");

      }).catch(error => {
      if (error.response && error.response.status === 422) {
        setFormErr(error.response.data.message);
      }
    })
      .finally(() => {
        setIsLoading(false);
        dispatch(toggleLoader(false));
      });
  }, [isLoading])

  return (
    <div id={"avdfsd"}>
      <ToggleLayout image={user} title={t("title.BASIC_DETAILS")} dropDown={false}
                    visibleToggleIndex={visibleToggleIndex}
                    toggleIndex={1}
                    onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
        <div className="table-actions float-right pdf-hide" data-toggle="tooltip" title="Download PDf">
          {/* <FeatherIcon className="table-action" icon={"download"} onClick={() => props.onPrint()}/> */}
        </div>
        <div className={"row"}>
          <div className={"col-12 mb-mw-min-content"}>
            <div className={"row justify-content-between gap-mb-24"}>
              <div className={"col-md-auto"}>
                <div className={"view-label"}>
                  {t("title.ASSIGNEE")}
                </div>
                <div className={"view-value"}>
                  {toCapsFirst(props.scout.assignee ? props.scout.assignee.lastName : "-")}
                </div>

                <div className={"mt-md-5 mt-3"}>
                  <div className={"view-label"}>
                    {t("title.SCOUTER")}
                  </div>
                  <div className={"view-value"}>
                    {props.scout.createdBy?.lastName ? toCapsFirst(props.scout.createdBy.lastName) : " - "}
                  </div>
                </div>
              </div>
              <div className={"col-md-auto "}>
                <div className={"view-label"}>
                  {t("title.DEADLINE")}
                </div>
                <div className={"view-value"}>
                  {props.scout.endDate ? props.scout.endDate : " - "}
                </div>

                <div className={"mt-md-5 mt-3"}>
                  <div className={"view-label"}>
                    {t("title.DESCRIPTION")}
                  </div>
                  <div className={"view-value"}>
                    {props.scout.description ? props.scout.description : " - "}
                  </div>
                </div>

              </div>
              <div className={"col-md-auto"}>
                <div className={"view-label"}>
                  {t("title.DATE_AND_TIME")}
                </div>
                <div className={"view-value"}>
                  {props.scout.time ? props.scout.time : " - "}
                </div>

                <div className={"mt-md-5 mt-3"}>
                  <div className={"view-label"}>
                    {t("title.CATEGORY")}
                  </div>
                  <div className={"view-value"}>
                    {props.scout.category ? props.scout.category.replace(/_/g, " ") : " - "}
                  </div>
                </div>
              </div>
              <div className={"col-md-auto"}>
                <div className={"view-label"}>
                  {t("title.STATUS")}
                </div>
                <div className={"view-value"}>
                  {props.scout.status ? props.scout.status : " - "}
                </div>

                <div className={"mt-md-5 mt-3"}>
                  <div className={"view-label"}>
                    {t("title.IMAGE")}
                  </div>
                  <div className={"view-value"}>
                    {props.scout.images && (
                      <div className={props.scout.images.length !== 0 ? 'p-2' : ''}>
                        <FarmImages imgs={props.scout.images}/>
                      </div>)}
                    {(
                      !props.scout.images || props.scout.images.length === 0) && (
                      <div className={"scout-value m-0"}>{t("emptyMsg.NO_IMAGES_AVAILABLE")}</div>)}

                  </div>
                </div>
              </div>
              <div className={"col-md-auto"}>
                <div className={"view-label"}>
                  {t("title.PLOT")}
                </div>
                <div className={"view-value"}>
                  {props.scout.plotId ? plotName : " - "}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"mt-5"}>
          <div className={"farm-view-heading"}>{t("title.RELATED_TASK")}</div>
          <div className="xin-pull-right">
                        <span className={'sa-table-icon-flex'}>
                            <button
                              className="sa-table-btn-secondary sa-table-btn-mute sa-table-float-right assign-task-w"
                              onClick={() => {
                                setIsAssignModalVisible(true);
                              }}>
                                <FeatherIcon
                                  icon={"upload"} className={"sa-table-icon-size"}/> <span
                              className={"sa-table-icon"}>{t("button.ASSIGN_TASK")}</span>
                            </button>
                            <button className="sa-table-btn-secondary sa-table-float-right"
                                    onClick={() => setIsAddModalVisible(true)}>
                                <FeatherIcon
                                  icon={"plus"} className={"sa-table-icon-size"}/> <span
                              className={"sa-table-icon"}>{t("button.ADD_TASK")}</span>
                            </button>
                        </span>
          </div>
        </div>
        <div className={'row mt-5'}>
          {relatedTask && relatedTask.length > 0 && relatedTask.map((task, index) => (
            <div className={'col-4'}>
              <div key={index} className="single-card ma-res-bot sa-cursor">
                <div className={"single-card-header "} onClick={() => {
                  setIsTaskView(true);
                  setTaskData(task);
                }}>
                  <div className="card-img-view">
                    {task.images && task.images.length !== 0 ?
                      <img src={task.images[0]} className="card-img" alt="corporate"/> :
                      <div className={"card-img d-flex text-center align-center view-label half-list-container"}>
                        No Images Available
                      </div>
                    }
                  </div>
                  <div className="card-header-text limit-charater-farmname">
                    <label className="heading">{task.note}</label>
                    <div title={t("title.CONTACT_NAME")} className="sub-text">{task.assignee.lastName}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {relatedTask.length === 0 && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div
                className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_TASK_AVAILABLE_FOR_THIS_SCOUT_PLEASE_ADD")}</div>
            </div>)
          }
        </div>


        {/*************************************************** AssignModelStartsHere ***************************************************/}
        {isAssignModelVisible && <div>
          <div className="sa-popup-bg ">
            <div className="sa-popup">
              <form className={'sa-modal-box-style'} onSubmit={handleSubmit}>
                <div className="sa-popup-header">
                  <span className={'sa-model-heading'}>{t("title.ASSIGN_TASK")}</span>
                  <div className="sa-popup-close-icon"><FeatherIcon onClick={() => {
                    setIsAssignModalVisible(false);
                  }} className={"sa-modal-close-icon"} icon={"x"}/>
                  </div>
                </div>
                <div className="sa-popup-content">
                  <div className="col-md-6 form-group m-b-16">
                    <div className="form-group m-b-16">
                      <label className={"labelSize"}>{t("title.SELECT_TASK")}</label>
                      <select name="id" className="sa-filter sa-table-float-left w-100 m-l-0"
                              onChange={handleChange}>
                        <option value={""}>{t("title.SELECT_EXISTING_TASK")}</option>
                        {tasksListAll.filter(e => !e.scoutReport).map((task, index) => (
                          <option key={index} value={task.id}>{task.note}</option>
                        ))}
                      </select>
                      {errors.id && (
                        <p className="warning-input-msg ">{errors.id}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="sa-popup-btn">
                  <button id="btnCancel" type={"button"}
                          className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style" onClick={() => {
                    setIsAssignModalVisible(false);
                  }}>{t("button.CANCEL")}
                  </button>
                  <button id="btnAdd"
                          className="sa-popup-secondary-btn-style">{t("button.ADD")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>}


        {isTaskView &&
          <FarmTaskView task={taskData} onClose={() => setIsTaskView(false)}/>
        }


        {/*************************************************** AssignModelEndsHere ***************************************************/}

        {isAddModelVisible && <AddTask scoutId={props.scout.id} defaultAssignee={props.scout.createdBy} onClose={() => {
          setIsAddModalVisible(false);
        }} onUpdate={() => setIsUpdate(!isUpdate)}/>}

      </ToggleLayout>
    </div>
  )
};

export default FarmScoutingView;
