const INIT_STATE = {
  enabledModules: {},
  farmModules: {},
  selectedCorporate: null,
  farmUser: [],
  selectedBuyer: null,
  selectedAccount: null,
  corporateList: [],
  corporateListFilter: [],
  getAccountList: [],
  accountListFilter: [],
  corporateChange: "INITIAL",
  buyerList: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case "USER_MODULES": {
      return {
        ...state,
        enabledModules: action.payload
      };
    }
    case "FARM_MODULES": {
      return {
        ...state,
        farmModules: action.payload
      };
    }
    case "SELECTED_CORPORATE": {
      return {
        ...state,
        selectedCorporate: action.payload
      };
    }
    case "FARM_USER": {
      return {
        ...state,
        farmUser: action.payload
      };
    }
    case "SELECTED_BUYER": {
      return {
        ...state,
        selectedBuyer: action.payload
      };
    }
    case "SELECTED_ACCOUNT": {
      return {
        ...state,
        selectedAccount: action.payload
      };
    }
    case "CORPORATE_LIST": {
      return {
        ...state,
        corporateList: action.payload
      };
    }
    case "FILTER_CORPORATE_LIST": {
      return {
        ...state,
        corporateListFilter: action.payload
      };
    }
    case "CORPORATE_CHANGE": {
      return {
        ...state,
        corporateChange: action.payload
      };
    }
    case "GET_ACCOUNT_LIST": {
      return {
        ...state,
        getAccountList: action.payload
      };
    }
    case "FILTER_ACCOUNT_LIST": {
      return {
        ...state,
        accountListFilter: action.payload
      };
    }
    case "GET_BUYER_LIST": {
      return {
        ...state,
        buyerList: action.payload
      };
    }
    default:
      return state;
  }
}
