import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {MODULE, TOGGLE, VIEW} from "../utils/Enum";
import {changeToggle, triggerExport} from "../shared/actions/setting";
import FeatherIcon from "feather-icons-react";
import {getFilterFarmList, getSelectedFarm} from "../actions/farm";
import {getSelectedPlot} from "../actions/plot";
import {useHistory, useLocation} from "react-router-dom";
import {getModule, isModule} from "../utils/Utils";
import * as _ from "underscore";
import {useTranslation} from 'react-i18next';
import {getRefCount, getReferences, getRole, isSuper, isSuperLevelUsers} from "../shared/utils/SharedAuthentication";
import {getFilterCorporateList, getFilterAccountList} from "../actions/module";

const steps = [
  {
    selector: '.map',
    content: 'Click here to view the map',
  }
];

const ToggleButtons = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [isScroll, setScroll] = useState(false);
  const [showToggle, setShowToggle] = useState(true);
  const [showTasksButton, setShowTasksButton] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const location = useLocation();
  const [bgView, setBgView] = useState(false);
  const [hideNavigation, setHideNavigation] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [toggleFixed, setToggleFixed] = useState(false);
  const {t, i18n} = useTranslation();

  const toggle = useSelector(state => {
    return state.setting.toggle
  });
  const view = useSelector(state => {
    return state.setting.view
  });

  const selectedAccount = useSelector(state => {
    return state.modules.selectedAccount
  });

  const selectedAccountName = selectedAccount ? selectedAccount.name : "";
  

  function changeToggleClick(toggle) {
    if (view === VIEW.FARM_LIST) {
      dispatch(getSelectedFarm({}));
    }
    if (view === VIEW.PLOT_LIST) {
      dispatch(getSelectedPlot({}));
    }
    dispatch(changeToggle(toggle));
  }

  function triggerExportClick(toggle) {
    dispatch(triggerExport(toggle));
  }

  useEffect(() => {
    if (isModule(location.pathname, MODULE.CASH_FLOW) || isModule(location.pathname, MODULE.CORPORATE) || isModule(location.pathname, MODULE.BUYER_PROFILE) || isModule(location.pathname, MODULE.ACCOUNT_SETTING) || isModule(location.pathname, MODULE.DAILY_UPDATE) || isModule(location.pathname, MODULE.MAP_DATA) || isModule(location.pathname, MODULE.BIRD_VIEW) || isModule(location.pathname, MODULE.XINDICATE) || isModule(location.pathname, MODULE.DASHBOARD)) {
      setShowToggle(false);
    } else {
      setShowToggle(true);
    }
  }, [location]);

  useEffect(() => {
    if (isModule(location.pathname, MODULE.CASH_FLOW) || isModule(location.pathname, MODULE.SMART_FARM) || isModule(location.pathname, MODULE.BIRD_VIEW) || isModule(location.pathname, MODULE.XINDICATE) || isModule(location.pathname, MODULE.DASHBOARD) || isModule(location.pathname, MODULE.SETTINGS)) {
      setShowTasksButton(false);
    } else {
      setShowTasksButton(true);
    }
  }, [location]);


  useEffect(() => {
    if (isModule(location.pathname, MODULE.DASHBOARD) || isModule(location.pathname, MODULE.CCR_PROFILE)) {
      setHideNavigation(true);
    } else {
      setHideNavigation(false);
    }
  }, [location]);

  window.addEventListener('scroll', function (e) {
    const scroll_position = window.scrollY;

    if (scroll_position > 5) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  });

  const selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });

  const selectedFarm = useSelector(state => {
    return state.farmList.selectedFarm;
  });

  const goToTasks = () => {
    history.push('/alltasks');
  };

  const goToScouts = () => {
    history.push('/all-scouts');
  };

  function goToHome() {

    if (getRole() !== 1000 && getRefCount() !== 1) {
      dispatch(getSelectedFarm({}));
    }
    const role = getRole();
    const refs = getRefCount();

    if (role === 300) {
      history.push('/system-config/processing-plant');
    }
    const farmUser = refs === 1 && (role >= 1000 && role <= 9999);
    switch (getModule(location.pathname)) {
      case MODULE.SMART_FARM:
        history.push(farmUser ? "/" + getReferences() + '/plot' : "/");
        break;
      case MODULE.DAILY_UPDATE:
        history.push(farmUser ? "/" + getReferences() + '/daily-update/scouting' : "/module/daily-update/farms");
        break;
      case MODULE.CASH_FLOW:
        history.push(farmUser ? "/" + getReferences() + '/cash-flow/yield' : "/module/cash-flow/farms");
        break;
      case MODULE.MAP_DATA:
        history.push(farmUser ? "/" + getReferences() + '/map-data/map' : "/module/map-data/farms");
        break;
      case MODULE.SETTINGS:
        history.push(farmUser ? '/' + getReferences() + '/settings/plots' : '/module/settings/farms');
        break;
      case MODULE.CORPORATE:
        history.push('/corporate');
        break;
      case MODULE.BUYER_PROFILE:
        history.push('/buyer');
        break;
      case MODULE.ACCOUNT_SETTING:
        history.push('/account');
        break;

    }
  }

  function goToPlot() {
    history.push("/" + selectedFarm.id + "/plot/" + selectedPlot.id + "/sensor");
  }

  function goToFarm() {
    switch (getModule(location.pathname)) {
      case MODULE.SMART_FARM:
        history.push("/" + selectedFarm.id + "/plot");
        break;
      case MODULE.SETTINGS:
        history.push("/" + selectedFarm.id + "/settings/plots");
        break;
    }
  }

  function lightBoxClose1() {
    setBgView(false);
    document.querySelector(".lightbox").classList.add("closed");
  }

  const farmList = useSelector(state => {
    return state.farmList.farmList
  });
  const corporateDataList = useSelector(state => {
    return state.modules.corporateList
  });
  const corporateListFilter = useSelector(state => {
    return state.modules.corporateListFilter
  });

  const accountDataList = useSelector(state => {
    return state.modules.getAccountList
  });

  const buyerList = useSelector(state => {
    return state.modules.buyerList
  });

  const [filteredCorporateDataList, setFilteredCorporateDataList] = useState([]);
  useEffect(() => {
    if (corporateDataList && corporateDataList.length > 0) {
      setFilteredCorporateDataList(corporateDataList);
    }
  }, [corporateDataList]);

  useEffect(() => {
    setSearchValue('')
  }, [location.pathname])

  function handleSearch(e) {
    let val = e.target.value;
    setSearchValue(val)
    if (val !== "") {
      let res = _.filter(farmList, function (item) {
        return _.values(_.pick(item, 'name', 'location')).toString().toLocaleLowerCase().includes(val.toLocaleLowerCase());
      });
      dispatch(getFilterFarmList([...res]));
    } else {
      dispatch(getFilterFarmList([...farmList]));
    }
  }

  function handleSearchCorporate(e) {
    let val = e.target.value;
    setSearchValue(val)
    if (val !== "") {
      let res = _.filter(filteredCorporateDataList, function (item) {
        return _.values(_.pick(item, 'id','name', 'contactName')).toString().toLocaleLowerCase().includes(val.toLocaleLowerCase());
      });
      dispatch(getFilterCorporateList([...res]));
    } else {
      dispatch(getFilterCorporateList([...filteredCorporateDataList]));
    }
  }

  function handleFilterCorporateByAccount(e) {
    let val = e.target.value;
    if (val !== "") {
      let res = _.filter(corporateDataList, function (item) {
        return item.accountId === val;
      });
      setFilteredCorporateDataList(res);
      dispatch(getFilterCorporateList([...res]));
    } else {
      setFilteredCorporateDataList(corporateDataList);
      dispatch(getFilterCorporateList([...corporateDataList]));
    }
  }

  function handleSearchAccount(e) {
    let val = e.target.value;
    setSearchValue(val)
    if (val !== "") {
      let res = _.filter(accountDataList, function (item) {
        return _.values(_.pick(item, 'name', 'contactName')).toString().toLocaleLowerCase().includes(val.toLocaleLowerCase());
      });
      dispatch(getFilterAccountList([...res]));
    } else {
      dispatch(getFilterAccountList([...accountDataList]));
    }
  }

  function searchEnable() {
    return VIEW.FARM_LIST === view && (isModule(location.pathname, MODULE.SETTINGS) || isModule(location.pathname, MODULE.SMART_FARM) || isModule(location.pathname, MODULE.CASH_FLOW) || isModule(location.pathname, MODULE.DAILY_UPDATE))
  }


  return (
    <div
      className={"map-toggle-btn pdf-hide " + (toggle === TOGGLE.LIST && isScroll && ![VIEW.DASHBOARD_LIST].includes(view) ? 'map-toggle-bg ' : ' ') + (toggleFixed ? "position-fixed" : "position-relative")}
      hidden={[VIEW.XINDICATE_ALL, VIEW.NOTIFICATION_ALL].includes(view)}>
      <div className="container container-toggle-btn" hidden={hideNavigation}>
        <div className={"sa-bread-crumb"}>
          <div className={"d-flex"}>
            {!(VIEW.FARM_LIST === view || location.pathname === "/corporate" || location.pathname === "/buyer" || location.pathname === "/account" || getRole() === 1000 || ([VIEW.BUYER_DETAIL].includes(view) && getRole() === 100000 && getRefCount() === 1) && (isModule(location.pathname, MODULE.SETTINGS) || isModule(location.pathname, MODULE.SMART_FARM) || isModule(location.pathname, MODULE.DAILY_UPDATE) || isModule(location.pathname, MODULE.CASH_FLOW))) &&
              <div className={"sa-bc-home sa-cursor"} onClick={() => goToHome()}><FeatherIcon
                className={"icon-in-btn-map"}
                icon="home"/></div>}
            <div
              className={"sa-bc-farm " + (view === VIEW.SENSOR_LIST ? "sa-cursor" : "") + (view === VIEW.FARM_LIST ? "sa-bc-right-border" : "")}
              onClick={() => goToFarm()}
              hidden={[VIEW.FARM_LIST, VIEW.XINDICATE_ALL, VIEW.CORPORATE_LIST, VIEW.BUYER_PROFILE, VIEW.ACCOUNT_SETTING].includes(view) || ([VIEW.BUYER_DETAIL].includes(view) && getRole() === 100000 && getRefCount() === 1)}>
              <FeatherIcon className={"sa-bc-arrow"} icon="chevron-right"/>
              <FeatherIcon className={"icon-in-btn"} icon="layers"/>
              <span className={"hide-768"}> {[VIEW.TASKS_ALL].includes(view) ? 'All Tasks' : ([VIEW.BUYER_DETAIL, VIEW.BUYER_USER].includes(view) ? '' : ([VIEW.ACCOUNT_USER].includes(view) ? selectedAccountName : selectedFarm.name))}</span>
            </div>
            <div className={"sa-bc-plot"} onClick={() => goToPlot()}
                 hidden={[VIEW.TASKS_ALL, VIEW.FARM_LIST, VIEW.FARM_VIEW, VIEW.PLOT_LIST, VIEW.XINDICATE_ALL, VIEW.CORPORATE_LIST, VIEW.WATER_RESOURCE, VIEW.CORPORATE_USER, VIEW.BUYER_PROFILE, VIEW.BUYER_DETAIL, VIEW.BUYER_USER, VIEW.ACCOUNT_SETTING, VIEW.ACCOUNT_USER].includes(view)}>
              <FeatherIcon className={"sa-bc-arrow"} icon="chevron-right"/>
              <FeatherIcon className={"icon-in-btn"} icon="layout"/>
              {selectedPlot.name}
            </div>

            {(!(location.pathname).includes("xindicate") && searchEnable()) &&
              <span className="sa-table-btn-mute sa-search-align border-0 h-36 w-100-all">
                                <input className={'sa-table-search sa-table-search-point font-14'}
                                       name={t("placeHolder.SEARCH")}
                                       value={searchValue || ''}
                                       onChange={handleSearch}
                                       autoComplete={"off"} type="text" placeholder="Search"/>
                                <FeatherIcon className={'sa-search-icon'}
                                             icon={"search"} width={'16px'}/>
          </span>}
            {isSuper() && (location.pathname.includes("home")) ? <div>{farmList.length}</div> : 
            location.pathname.includes("account") ? <div>{accountDataList.length}</div> : 
            location.pathname.includes("corporate") ? <div>{filteredCorporateDataList.length}</div> : 
            location.pathname.includes("buyer") ? <div>{buyerList.length}</div> : 
            <div></div>}

            {location.pathname === "/corporate" &&
              (<span className="sa-table-btn-mute sa-search-align border-2 h-36 w-100-all">
                                <input className={'sa-table-search sa-table-search-point font-14'}
                                       name={t("placeHolder.SEARCH")}
                                       value={searchValue || ''}
                                       onChange={handleSearchCorporate}
                                       autoComplete={"off"} type="text" placeholder="Search"/>
                                <FeatherIcon className={'sa-search-icon'}
                                             icon={"search"} width={'16px'}/>

              <span className={'sa-table-float-left participant-filter'} style={{ marginLeft: 8 }} name={''} onChange={handleFilterCorporateByAccount}>
                <select className="sa-filter sa-mobile-view-m" >
                  <option value={""}>{"Account"}</option>
                  {accountDataList && accountDataList.map((item, index) => (
                    <option key={index} value={item.id}>{item.name}</option>
                  ))}
                </select>
              </span>
          </span>
          )}
          {location.pathname === "/account" &&
              <span className="sa-table-btn-mute sa-search-align border-0 h-36 w-100-all">
                                <input className={'sa-table-search sa-table-search-point font-14'}
                                       name={t("placeHolder.SEARCH")}
                                       value={searchValue || ''}
                                       onChange={handleSearchAccount}
                                       autoComplete={"off"} type="text" placeholder="Search"/>
                                <FeatherIcon className={'sa-search-icon'}
                                             icon={"search"} width={'16px'}/>
          </span>}
          </div>
        </div>
        <div className="pull-right toggle-btn" hidden={!showToggle || [VIEW.TASKS_ALL].includes(view)}>
          <button id="toggle-list"
                  className={"list first-tog first-tog-size " + (toggle === TOGGLE.LIST ? 'p-l-11 height-44 tog-sa-primary hide-768 ' : 'tog-sa-secondary ')}
                  onClick={() => {
                    changeToggleClick(TOGGLE.LIST)
                    setToggleFixed(false)
                  }}>
            <FeatherIcon className={"icon-in-btn-map"} icon="grid"/> <span className={"hide-768"}> LIST</span>
          </button>
          {/*{ bgView ?*/}
          {/*    <div className="lightBoxCopy">*/}

          {/*    </div>:<div></div>*/}
          {/*}*/}

          {/*<div className="lightbox hide-768">*/}
          {/*  <div className="iframeContainer up-arrow ">*/}
          {/*    <div className="toolbarLB">*/}
          {/*      <span className="closeLB" onClick={()=>lightBoxClose1()}>x</span>*/}
          {/*      <div>SenzAgro is a powerful combination of climate-smart soil and irrigation automation with*/}
          {/*        advance cloud-based Ag intelligence....*/}
          {/*      </div>*/}
          {/*      <button className={"ok-button"}  onClick={()=>lightBoxClose1()}>ok</button>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <button id="toggle-map"
                  className={"map second-tog second-tog-size " + (toggle === TOGGLE.MAP ? 'p-l-11 height-44 tog-sa-primary hide-768' : 'tog-sa-secondary')}
                  onClick={() => {
                    changeToggleClick(TOGGLE.MAP)
                    setToggleFixed(true)
                  }}>
            <FeatherIcon className={"icon-in-btn-map"} icon="map"/> <span className={"hide-768"}> MAP</span>
          </button>
        </div>
        {isSuperLevelUsers() &&
          <div className="pull-right toggle-btn" hidden={!showToggle || [VIEW.TASKS_ALL].includes(view)}>
            <button id="toggle-map"
                    className={"map second-tog second-tog-size " + (toggle === TOGGLE.MAP ? 'p-l-11 height-44 tog-sa-primary hide-768' : 'tog-sa-secondary')}
                    onClick={() => {
                      triggerExportClick("FARMS")
                      setToggleFixed(true)
                    }}>
              <FeatherIcon className={"icon-in-btn-map"} icon="map"/> <span className={"hide-768"}> Export</span>
            </button>
          </div>
        }
        <div className="pull-right toggle-btn" hidden={!showTasksButton}>
          <button className="pull-right sa-table-btn-secondary sa-table-float-right w-164" onClick={goToTasks}>
            <FeatherIcon
              icon={"check-circle"} className={"sa-table-icon-size"}/> <span
            className={"sa-table-icon"}>{t("button.All_ASSIGNED_TASKS")}</span>
          </button>
          {isSuperLevelUsers() &&
            <button className="pull-right sa-table-btn-secondary sa-table-float-right w-164" onClick={goToScouts}>
              <FeatherIcon
                icon={"check-circle"} className={"sa-table-icon-size"}/> <span
              className={"sa-table-icon"}>{t("button.All_ASSIGNED_SCOUTS")}</span>
            </button>
          }
        </div>
      </div>
    </div>

  )
};

export default ToggleButtons
