import React, { useState } from "react";
import Switch from "react-switch";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getUserId } from "../../shared/utils/SharedAuthentication";
import { formatDisplayEnumValue } from "../../utils/Utils";

export default function NotificationSetting({ referenceId }) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [categories, setCategories] = useState([]);  

  const fetchSettingsAndCategories = async () => {
    try {
      const userId = getUserId();
      const reference = referenceId;

      const settingsResponse = await axios.get(
        `${process.env.REACT_APP_HOST}/user/${userId}/reference/${reference}/notification-settings`
      );

      const savedSettings = settingsResponse.data?.content || [];

      if (savedSettings.length > 0) {
        const updatedCategories = savedSettings.map((setting) => ({
          notificationCategory: setting.notificationCategory,
          enable: Object.values(setting.notificationTypes).some((v) => v),
          notificationTypes: setting.notificationTypes,
        }));
        setCategories(updatedCategories);
      } else {
        const categoryResponse = await axios.get(
          `${process.env.REACT_APP_HOST}/user/${userId}/notification-category`
        );
        const defaultCategories = categoryResponse.data.map((category) => ({
          notificationCategory: category,
          enable: false,
          notificationTypes: { EMAIL: false, SMS: false, PUSH_NOTIFICATION: false },
        }));
        setCategories(defaultCategories);
      }
    } catch (error) {
      console.error("Error fetching notification settings or categories:", error);
    } 
  };

  const openModal = () => {
    setIsModalVisible(true);
    fetchSettingsAndCategories();
  };

  const handleCategoryToggle = (index, field, value) => {
    const updatedCategories = [...categories];
    updatedCategories[index][field] = value;
    setCategories(updatedCategories);
  };

  const handleModeToggle = (index, mode, value) => {
    const updatedCategories = [...categories];
    updatedCategories[index].notificationTypes[mode] = value;
    setCategories(updatedCategories);
  };

  const saveNotificationSettings = async () => {
    try {
      const reference = referenceId;
      const userId = getUserId();

      const transformedData = categories.map((category) => ({
        notificationCategory: category.notificationCategory,
        notificationTypes: category.notificationTypes,
      }));

      const response = await axios.put(
        `${process.env.REACT_APP_HOST}/user/${userId}/reference/${reference}/notification-settings`,
        transformedData
      );

      console.log("Notification settings updated successfully:", response.data);
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error saving notification settings:", error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveNotificationSettings();
  };

  return (
    <div>
      <FeatherIcon onClick={openModal} icon={"bell"} />
      {isModalVisible && (
        <div className="sa-popup-bg" style={{ cursor: "auto" }}>
          <div className="sa-popup">
            <div className="sa-modal-box-style">
              <div className="sa-popup-header">
                <span className={"sa-model-heading"}>
                  {t("title.EDIT_NOTIFICATION_SETTINGS")}
                </span>
                <div
                  className="sa-popup-close-icon"
                  onClick={() => setIsModalVisible(false)}
                >
                  <FeatherIcon className={"sa-modal-close-icon"} icon={"x"} />
                </div>
              </div>
              <div className="container">
                <form onSubmit={handleSubmit}>
                  {categories.map((category, index) => (
                    <div key={index}>
                      <div className="d-flex align-items-center mb-2">
                        <p className={"mr-3 mb-0"}>{formatDisplayEnumValue(category.notificationCategory)}</p>
                        <Switch
                          className="react-switch mt-1"
                          onChange={(checked) =>
                            handleCategoryToggle(index, "enable", checked)
                          }
                          checked={category.enable}
                          aria-labelledby="neat-label"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          offColor="#D3D3D3"
                          onColor={"#23ad32"}
                          height={16}
                          width={30}
                        />
                      </div>
                      {category.enable && (
                        <div className="row mb-3">
                          <div className="col-md-4">
                            <div className="form-group m-b-16 notification-box">
                              {t("title.EMAIL")}
                              <div className={"ml-auto"}>
                                <Switch
                                  className="react-switch"
                                  onChange={(checked) =>
                                    handleModeToggle(index, "EMAIL", checked)
                                  }
                                  checked={category.notificationTypes.EMAIL}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  offColor="#D3D3D3"
                                  onColor={"#23ad32"}
                                  height={16}
                                  width={30}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group m-b-16 notification-box">
                              {t("title.SMS")}
                              <div className={"ml-auto"}>
                                <Switch
                                  className="react-switch"
                                  onChange={(checked) =>
                                    handleModeToggle(index, "SMS", checked)
                                  }
                                  checked={category.notificationTypes.SMS}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  offColor="#D3D3D3"
                                  onColor={"#23ad32"}
                                  height={16}
                                  width={30}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group m-b-16 notification-box">
                              {t("title.MOBILE_PUSH_NOTIFICATION")}
                              <div className={"ml-auto"}>
                                <Switch
                                  className="react-switch"
                                  onChange={(checked) =>
                                    handleModeToggle(index, "PUSH_NOTIFICATION", checked)
                                  }
                                  checked={category.notificationTypes.PUSH_NOTIFICATION}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  offColor="#D3D3D3"
                                  onColor={"#23ad32"}
                                  height={16}
                                  width={30}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="sa-popup-btn">
                    <button
                      type={"button"}
                      className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style update-size"
                      onClick={() => setIsModalVisible(false)}
                    >
                      {t("button.CANCEL")}
                    </button>
                    <button
                      className="sa-popup-secondary-btn-style update-size"
                      type="submit"
                    >
                      {t("button.UPDATE")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}



