import React, {useCallback, useState} from 'react';
import ImgsViewer from 'react-images-viewer';
import { generatePresignedUrl } from '../../utils/Utils';


const FarmImages = (props) => {

  let ImgSet = [];
  props.imgs.map((source, index) => {
    ImgSet.push(
      {src: source.includes("senzagro-app") ? source : generatePresignedUrl(source)}
    )

  });

  const [currImg, setCurrImg] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrImg(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrImg(0);
    setIsViewerOpen(false);
  };

  const goToPrevImg = () => {
    setCurrImg(currImg - 1);
  };
  const goToNextImg = () => {
    setCurrImg(currImg + 1);
  };

  return (
    <div>
      {props.imgs.map((src, index) => (
        <img
          src={src.includes("senzagro-app") ? src : generatePresignedUrl(src)}
          onClick={() => openImageViewer(index)}
          width={props.width ? props.width : "60"}
          height={props.width ? props.width : "60"}
          key={index}
          style={{margin: '2px', cursor: "pointer"}}
          alt=""/>
      ))}

      {isViewerOpen && (
        <ImgsViewer
          currImg={currImg}
          imgs={ImgSet}
          isOpen={isViewerOpen}
          onClose={closeImageViewer}
          onClickPrev={goToPrevImg}
          onClickNext={goToNextImg}
          preloadNextImg={true}
        />
      )}
    </div>
  );
}

export default FarmImages;
